import React, { Component } from "react";

class Section1Mobile extends Component {
  state = {
    leftOpacity: 0,
    rightOpacity: 0,
    translateRight: 0,
    translateRightPropertie: 0,
  };
  componentDidUpdate(prevProps) {
    if (this.props.pagescrolled !== prevProps.pagescrolled) {
      var st = this.props.pagescrolled;
      this.scrollAnimations(st);
    }
  }
  scrollAnimations = (scroll) => {
    var ele_left = document.querySelector("#section1 .column.left");
    var ele_right = document.querySelector("#section1 .column.right");

    var LeftOpacity;
    var RightOpacity;
    var TranslateRight;
    var TranslateRightPropertie;

    var ele_offsettop_right = ele_right.offsetTop;

    /*** Opacities animation */
    var scrollPercentage =
      (100 * this.props.pagescrolled) / ele_left.clientHeight;
    LeftOpacity = scrollPercentage * 0.05 < 1 ? scrollPercentage * 0.05 : 1;
    RightOpacity = scrollPercentage * 0.1 < 0.5 ? scrollPercentage * 0.1 : 0.5;
    this.setState({
      leftOpacity: LeftOpacity,
    });
    this.setState({
      rightOpacity: RightOpacity,
    });

    /*** translate Right animation */
    TranslateRight = this.state.translateRight + (scroll - ele_offsettop_right);
    TranslateRightPropertie = "translate3d(0px," + TranslateRight + "px, 0px)";

    if (TranslateRight >= 0) {
      TranslateRightPropertie =
        "translate3d(0px," + TranslateRight + "px, 0px)";
    } else {
      TranslateRightPropertie = "translate3d(0px, 0px, 0px)";
    }

    this.setState({
      translateRightPropertie: TranslateRightPropertie,
    });
  };
  render() {
    return (
      <div
        id="section1"
        className="section content_container mobile"
        style={{
          width: this.props.wd.ww,
          height: this.props.wd.sh,
          backgroundImage: "url(images/equipa.jpg)",
        }}
      >
        <div
          className="column left"
          style={{
            backgroundColor: `rgba(0, 0, 0, ${this.state.leftOpacity})`,
          }}
        >
          <img
            alt="Imagem Jiu Jiteiros"
            className="pawn opacity_animation"
            src="images/pawn1.png"
            style={{
              opacity: this.state.leftOpacity,
            }}
          />
        </div>
        <div
          className="column right"
          style={{
            backgroundColor: "#fec908",
          }}
        >
          <div className="content">
            <img
              alt="Imagem título Jiu Jitsu"
              className="title title_lg transitionY_animation opacity_animation"
              src="images/section1_blacktitle.png"
              style={{
                opacity: this.state.leftOpacity,
                transform: this.state.translateRightPropertie,
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Section1Mobile;
