import React, { Component } from "react";
import SlideEvents2 from "../layout/SlideEvents2";

class Section4Academy extends Component {
  render() {
    return (
      <div
        id="Section4Academy"
        className="section content_container"
        style={{
          width: this.props.wd.ww,
          height: this.props.wd.sh / 2,
        }}
      >
        <div className="column left">
          <div className="content">
            <img
              className="title"
              alt="Imagem do Título Graduação"
              src="images/section4academy_lefttitle.png"
            />
            <p>
              E foi no dia 29 de Fevereiro a nossa última Graduação. Temos novos
              grauados na equipa. Aqui ficam algumas imagens de mais um dia
              replecto de emoções em equipa.
            </p>
          </div>
        </div>
        <div className="column right" style={{ height: this.props.wd.sh }}>
          <SlideEvents2 openVideoModal={this.props.openVideoModal} />
        </div>
      </div>
    );
  }
}

export default Section4Academy;
