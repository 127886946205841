import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import Marker from "../layout/Marker";

const mapStyles = [
  {
    featureType: "all",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#000000",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#000000",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry.fill",
    stylers: [
      {
        visibility: "on",
      },
      {
        hue: "#ff0000",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [
      {
        color: "#000000",
      },
      {
        lightness: 19,
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#000000",
      },
      {
        lightness: 17,
      },
    ],
  },
  {
    featureType: "road",
    stylers: [
      { color: "#fec908" },
      {
        lightness: 30,
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [{ lightness: -100 }],
  },
];

class Section8AcademyMobile extends Component {
  state = {
    apikey: "AIzaSyBs7YWIQOsSzsBwG9wf3PZbU1HXh65FfKg",
    center: {
      lat: 38.713302,
      lng: -9.409100,
    },
    zoom: 18,
  };
  render() {
    return (
      <div
        id="Section8Academy"
        className="section content_container"
        style={{
          width: this.props.wd.ww,
        }}
      >
        <div
          id="section5_anchor"
          className="column left"
          style={{
            height: this.props.wd.sh / 2,
          }}
        >
          <div className="content">
            <img
              alt="Imagem do titlo localidade"
              className="title title_lg"
              src="images/section8academy_lefttitle.png"
            />
            <p>
              Para nos conhecer melhor, faça uma visita. Adorariamos conhecê-lo.
              Estamos na Rua Carlos Anjos, lote 2, rc direito, Amoreira, 2645-174 Alcabideche.
            </p>
          </div>
        </div>
        <div className="column right" style={{ height: this.props.wd.sh }}>
          <GoogleMapReact
            id="map"
            style={{
              width: "100%",
              height: "100%",
              overflowY: "hidden",
              position: "absolute",
              zIndex: 1,
              top: "0",
              left: "0",
            }}
            bootstrapURLKeys={{ key: this.state.apikey }}
            defaultCenter={this.state.center}
            defaultZoom={this.state.zoom}
            options={{
              styles: mapStyles,
              scrollwheel: false,
              minZoom: 2,
              backgroundColor: "#203331",
            }}
          >
            <Marker lat={38.712352} lng={-9.408650} text="My Marker" />
          </GoogleMapReact>
        </div>
      </div>
    );
  }
}

export default Section8AcademyMobile;
