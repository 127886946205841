import React, { Component } from "react";
import SlideAcademy from "../layout/SlideAcademy";

class Section7Academy extends Component {
  render() {
    return (
      <div
        id="Section7Academy"
        className="section content_container"
        style={{
          width: this.props.wd.ww,
          height: this.props.wd.sh / 2,
          marginTop: this.props.wd.sh / 2,
        }}
      >
        <div
          id="section4_anchor"
          className="column left"
          style={{
            height: this.props.wd.sh,
            marginTop: -this.props.wd.sh / 2,
          }}
        >
          <SlideAcademy openVideoModal={this.props.openVideoModal} />
        </div>
        <div className="column right" style={{ height: this.props.wd.sh / 2 }}>
          <div className="content">
            <img
              alt="Imagem Título Academia"
              className="title title_sm"
              src="images/section7academy_righttitle.png"
            />
            <p>
              Deixamos, a quem quiser expreitar, recordações de alguns momentos
              passados em equipa.
              <br /> Venha criar novas connosco.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Section7Academy;
