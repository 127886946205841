import React, { Component } from "react";
import { Link } from "react-router-dom";

class Section4 extends Component {
  state = {
    translateLeft: 0,
    translateLeftPropertie: 0,
    leftOpacity: 0,
  };
  componentDidMount() {
    this.setState({
      translateLeft: -this.props.wd.sh * 0.7,
    });
  }
  componentDidUpdate(prevProps) {
    if (this.props.pagescrolled !== prevProps.pagescrolled) {
      var st = this.props.pagescrolled;
      this.setTranslate(st);
    }
  }
  setTranslate = (scroll) => {
    var ele = document.getElementById("section4");
    var TranslateLeft;
    var TranslateLeftPropertie;
    var LeftOpacity;
    var startAnimFactor = 0.3 * this.props.wd.wh;

    var ele_offsettop = ele.offsetTop;
    /*** set opacity Animation */
    var sectionScrollPercentageWithFactor =
      (100 * (scroll - (ele_offsettop - startAnimFactor))) / ele.clientHeight;
    if (scroll > ele_offsettop - startAnimFactor) {
      LeftOpacity =
        sectionScrollPercentageWithFactor * 0.05 < 1
          ? sectionScrollPercentageWithFactor * 0.05
          : 1;
    } else {
      LeftOpacity = 0;
    }
    this.setState({
      leftOpacity: LeftOpacity,
    });
    /*** set translate left Animation */
    TranslateLeft =
      this.state.translateLeft +
      (scroll - ele_offsettop + this.props.navheight);
    if (TranslateLeft > 0) {
      TranslateLeftPropertie = "translate3d(0px, 0px, 0px)";
    } else {
      TranslateLeftPropertie = "translate3d(0px," + TranslateLeft + "px, 0px)";
    }
    this.setState({
      translateLeftPropertie: TranslateLeftPropertie,
    });
  };
  render() {
    return (
      <div
        id="section4"
        className="section content_container"
        style={{
          width: this.props.wd.ww,
          height: this.props.wd.sh,
        }}
      >
        <div
          className="column left"
          style={{ backgroundImage: "url(images/section4_background1.jpg)" }}
        >
          <div
            className="backgroundImage opacity_animation"
            style={{
              backgroundImage: "url(images/section4_background2.jpg)",
              opacity: this.state.leftOpacity,
            }}
          ></div>
          <div className="content">
            <img
              alt="Título Equipa"
              className="title title_sm opacity_animation"
              src="images/section4_blacktitle.png"
              style={{ opacity: this.state.leftOpacity }}
            />
            <p
              className="transitionY_animation"
              style={{ transform: this.state.translateLeftPropertie }}
            >
              É uma academia de Jiu Jitsu sem faixa etária. Pequenos ou graúdos,
              o que todos os praticantes têm em comum é uma enorme vontade de
              aqui estarem... e nós, de os receber. Muito virada para a
              competição a equipa não deixa de abraçar quem quer experimentar
              este novo mundo, seja qual for o objectivo. Se quiser saber mais
              sobre a equipa, os alunos ou algumas novidades clique em saber
              mais.
            </p>
            <Link to="/academia#section1_anchor">
              <div
                className="btn from-top"
                style={{ opacity: this.state.leftOpacity }}
              >
                <p>Saber mais</p>
              </div>
            </Link>
          </div>
        </div>
        <div
          className="column right"
          style={{
            backgroundColor: `rgba(0, 0, 0, 1)`,
          }}
        >
          <div className="content">
            <img
              alt="Título Academia"
              className="title title_sm"
              src="images/section4_whitetitle.png"
            />
            <div className="text_content">
              <p>
                A academia começou em 2015. Abriu nos Bombeiros de Alcabideche
                no início da época com o Professor Nelson Lopes. Desde essa
                altura a equipa tem vindo a crescer.
                <br /> Os preços podem variar entre 45€ mensais ou 52,5€ para
                quem gosta de complementar o treino com ginásio. Aqui tentamos
                oferecer as melhores condições para os nossos atletas.
              </p>
              <Link to="/academia#section4_anchor">
                <div className="btn from-bottom">
                  <p>Saber mais</p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Section4;
