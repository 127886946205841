import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import Marker from "../layout/Marker";

const mapStyles = [
  {
    featureType: "all",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#000000",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#000000",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry.fill",
    stylers: [
      {
        visibility: "on",
      },
      {
        hue: "#ff0000",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [
      {
        color: "#000000",
      },
      {
        lightness: 19,
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#000000",
      },
      {
        lightness: 17,
      },
    ],
  },
  {
    featureType: "road",
    stylers: [
      { color: "#fec908" },
      {
        lightness: 30,
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [{ lightness: -100 }],
  },
];

class Section6 extends Component {
  state = {
    translateLeft: 0,
    translateLeftPropertie: 0,
    leftOpacity: 0,
    apikey: "AIzaSyBs7YWIQOsSzsBwG9wf3PZbU1HXh65FfKg",
    center: {
      lat: 38.712402,
      lng: -9.409700,
    },
    zoom: 18,
  };
  componentDidMount() {
    this.setState({
      translateLeft: -this.props.wd.sh * 0.7,
    });
  }
  componentDidUpdate(prevProps) {
    if (this.props.pagescrolled !== prevProps.pagescrolled) {
      var st = this.props.pagescrolled;
      this.setTranslate(st);
    }
  }
  setTranslate = (scroll) => {
    var ele = document.getElementById("section6");
    var TranslateLeft;
    var TranslateLeftPropertie;
    var LeftOpacity;
    var startAnimFactor = 0.3 * this.props.wd.wh;

    var ele_offsettop = ele.offsetTop;
    /*** set opacity Animation */
    var sectionScrollPercentageWithFactor =
      (100 * (scroll - (ele_offsettop - startAnimFactor))) / ele.clientHeight;
    if (scroll > ele_offsettop - startAnimFactor) {
      LeftOpacity =
        sectionScrollPercentageWithFactor * 0.05 < 1
          ? sectionScrollPercentageWithFactor * 0.05
          : 1;
    } else {
      LeftOpacity = 0;
    }
    this.setState({
      leftOpacity: LeftOpacity,
    });
    /*** set translate left Animation */
    TranslateLeft =
      this.state.translateLeft +
      (scroll - ele_offsettop + this.props.navheight);
    if (TranslateLeft >= 0) {
      TranslateLeftPropertie = "translate3d(0px," + TranslateLeft + "px, 0px)";
    } else {
      TranslateLeftPropertie = "translate3d(0px, 0px, 0px)";
    }

    this.setState({
      translateLeftPropertie: TranslateLeftPropertie,
    });
  };
  render() {
    return (
      <div
        id="section6"
        className="section content_container"
        style={{
          width: this.props.wd.ww,
          height: this.props.wd.sh,
          position: "relative",
        }}
      >
        <GoogleMapReact
          id="map"
          style={{
            width: "100%",
            height: "100%",
            overflowY: "hidden",
            position: "absolute",
            zIndex: 1,
            top: "0",
            left: "0",
          }}
          bootstrapURLKeys={{ key: this.state.apikey }}
          defaultCenter={this.state.center}
          defaultZoom={this.state.zoom}
          options={{
            styles: mapStyles,
            scrollwheel: false,
            minZoom: 2,
            backgroundColor: "#203331",
          }}
        >
          <Marker lat={38.712352} lng={-9.408650} text="My Marker" />
        </GoogleMapReact>
        <div
          className="column left"
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            zIndex: 1,
            backgroundColor: `rgba(254, 201, 8, 1)`,
            opacity: this.state.leftOpacity,
          }}
        >
          <div className="content">
            <img
              alt="Imagem do Título Localização"
              className="title"
              src="images/section6_blacktitle.png"
            />
            <div
              className="text_content transitionY_animation"
              style={{ transform: this.state.translateLeftPropertie }}
            >
              <p>
                Para nos conhecer melhor, faça uma visita.
                <br />
                Adorariamos conhecê-lo.
                <br />
                <br />
                Estamos na Rua Carlos Anjos, <br />
                lote 2, rc direito, Amoreira, 2645-174 Alcabideche.
              </p>
              <p>
                E se nos quiser conhecer melhor sem sair do sofá vá às nossas
                redes sociais. Se ainda não conseguimos, esperemos que o
                cativem.
              </p>
              <div className="social_containers">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/poundforpoundcascais/"
                >
                  <div className="social_container facebook_container">
                    <img
                      alt="Imagem facebook ícone"
                      src="images/logo_facebook_white.png"
                    />
                    <img
                      alt="Imagem facebook ícone on hover"
                      className="hover"
                      src="images/logo_facebook.png"
                    />
                  </div>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/p4p_alcabideche/"
                >
                  <div className="social_container instagram_container">
                    <img
                      alt="Imagem instagram ícone"
                      src="images/logo_instagram_white.png"
                    />
                    <img
                      alt="Imagem instagram ícone on hover"
                      className="hover"
                      src="images/logo_instagram.png"
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className="column right"
          style={{ position: "absolute", top: "0", right: "0" }}
        ></div>
      </div>
    );
  }
}

export default Section6;
