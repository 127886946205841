import React, { Component } from "react";

class Section1Academy extends Component {
  render() {
    return (
      <div
        id="section1Academy"
        className="section content_container"
        style={{
          width: this.props.wd.ww,
          height: this.props.wd.sh / 2,
          marginTop: this.props.navheight,
        }}
      >
        <img
          alt="Imagem do título Campeonatos"
          className="title title_sm"
          src="images/section1academy_title.png"
        />
        <div className="column left"></div>
        <div className="column right"></div>
      </div>
    );
  }
}

export default Section1Academy;
