import React, { Component } from "react";
import { Link } from "react-router-dom";

class Section3Mobile extends Component {
  state = {
    translateLeft: 0,
    translateLeftPropertie: 0,
    translateRight: 0,
    translateRightPropertie: 0,
    leftOpacity: 0,
    rigthOpacity: 0,
  };
  componentDidMount() {
    this.setState({
      translateLeft: -this.props.wd.sh * 0.7,
    });
  }
  componentDidUpdate(prevProps) {
    if (this.props.pagescrolled !== prevProps.pagescrolled) {
      var st = this.props.pagescrolled;
      this.setTranslate(st);
    }
  }
  setTranslate = (scroll) => {
    var ele_left = document.querySelector("#section3 .column.left");
    var ele_right = document.querySelector("#section3 .column.right");

    var TranslateRight;
    var TranslateRightPropertie;
    var TranslateLeft;
    var TranslateLeftPropertie;
    var LeftOpacity;
    var RightOpacity;
    var startAnimFactor = 0.3 * this.props.wd.wh;

    var ele_offsettop_left = ele_left.offsetTop;
    var ele_offsettop_right = ele_right.offsetTop;
    /*** set translate left Animation */
    TranslateLeft = this.state.translateLeft + (scroll - ele_offsettop_left);
    TranslateLeftPropertie = "translate3d(0px," + TranslateLeft + "px, 0px)";

    this.setState({
      translateLeftPropertie: TranslateLeftPropertie,
    });
    /*** set opacity Animation */
    var sectionScrollPercentageWithFactorLeft =
      (100 * (scroll - (ele_offsettop_left - startAnimFactor))) /
      ele_left.clientHeight;
    if (scroll > ele_offsettop_left - startAnimFactor) {
      LeftOpacity =
        sectionScrollPercentageWithFactorLeft * 0.05 < 1
          ? sectionScrollPercentageWithFactorLeft * 0.05
          : 1;
    } else {
      LeftOpacity = 0;
    }
    this.setState({
      leftOpacity: LeftOpacity,
    });
    /*** set translate right Animation */
    var sectionScrollPercentageWithFactorRight =
      (100 * (scroll - (ele_offsettop_right - startAnimFactor))) /
      ele_right.clientHeight;
    if (scroll > ele_offsettop_right - startAnimFactor) {
      RightOpacity =
        sectionScrollPercentageWithFactorRight * 0.05 < 1
          ? sectionScrollPercentageWithFactorRight * 0.05
          : 1;
    } else {
      RightOpacity = 0;
    }
    this.setState({
      rightOpacity: RightOpacity,
    });
    /*** translate ***/
    TranslateRight = this.state.translateRight + (scroll - ele_offsettop_right);
    if (TranslateRight > 0) {
      TranslateRightPropertie = "translate3d(0px, 0px, 0px)";
    } else {
      TranslateRightPropertie =
        "translate3d(0px," + TranslateRight + "px, 0px)";
    }
    this.setState({
      translateRightPropertie: TranslateRightPropertie,
    });
  };
  render() {
    return (
      <div
        id="section3"
        className="section content_container section_dif_mobile"
        style={{
          width: this.props.wd.ww,
          height: this.props.wd.sh,
        }}
      >
        <div className="column left">
          <div className="content">
            <img
              alt="Título Professor"
              className="title title_lg opacity_animation"
              src="images/section3_whitetitle.png"
              style={{ opacity: this.state.leftOpacity }}
            />
            <p
              className="transitionY_animation"
              style={{ transform: this.state.translateLeftPropertie }}
            >
              O Professor Nelson Lopes iniciou o seu percurso no jiu jitsu em
              2004 com o professor Fábio Fetter. Em competição, já ganhou
              diversos títulos a nivel nacional e a faixa preta foi conquistada
              em 2014. Desde essa altura começou a considerar um caminho no jiu
              jitsu, não só como atleta mas também, como Professor desta
              modalidade. Actualmente, e já desde 2015, ensina nos bombeiros de
              Alcabideche.
            </p>
          </div>
        </div>
        <div
          className="column right"
          style={{ backgroundImage: "url(images/section4_background1.jpg)" }}
        >
          <div
            className="backgroundImage opacity_animation"
            style={{
              backgroundImage: "url(images/section4_background2.jpg)",
              opacity: this.state.rightOpacity,
            }}
          ></div>
          <div className="content">
            <img
              alt="Título Academia"
              className="title opacity_animation"
              src="images/section4_blacktitle.png"
              style={{ opacity: this.state.rightOpacity }}
            />
            <p
              className="transitionY_animation"
              style={{ transform: this.state.translateRightPropertie }}
            >
              É uma academia de Jiu Jitsu sem faixa etária. Pequenos ou graúdos,
              o que todos os praticantes têm em comum é uma enorme vontade de
              aqui estarem... e nós, de os receber. Muito virada para a
              competição a equipa não deixa de abraçar quem quer experimentar
              este novo mundo, seja qual for o objectivo. Se quiser saber mais
              sobre a equipa, os alunos ou algumas novidades clique em saber
              mais.
            </p>
            <Link to="/academiamobile#section1_anchor">
              <div
                className="btn from-top"
                style={{ opacity: this.state.rightOpacity }}
              >
                <p>Saber mais</p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Section3Mobile;
