import React, { Component } from "react";
import SlideChampionships from "../layout/SlideChampionships";

class Section2AcademyMobile extends Component {
  render() {
    return (
      <div
        id="Section2Academy"
        className="section content_container"
        style={{
          width: this.props.wd.ww,
        }}
      >
        <div
          id="section1_anchor"
          className="column left"
          style={{
            height: this.props.wd.sh / 2,
          }}
        >
          <div className="content">
            <img
              alt="Imagem Título Campeonatos"
              className="title title_lg"
              src="images/section2academy_lefttitle.png"
            />
            <p>
              Muitos dos nossos alunos gostam de competir e nós incentivamos.
              Veja aqui algumas imagens de eventos em que estivemos presentes.
            </p>
          </div>
        </div>
        <div className="column right" style={{ height: this.props.wd.sh }}>
          <SlideChampionships openVideoModal={this.props.openVideoModal} />
        </div>
      </div>
    );
  }
}

export default Section2AcademyMobile;
