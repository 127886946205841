import React, { Component } from "react";
import axios from "axios";
import $ from "jquery";

class Section7 extends Component {
  state = {
    translateLeft: 0,
    translateLeftPropertie: 0,
    leftOpacity: 0,
    name: "",
    email: "",
    msg: "",
    // email_url: "http://localhost/onIT/poundforpound/site/send_email.php",
    email_url: "/send_email.php",
  };
  componentDidMount() {
    this.setState({
      translateLeft: -this.props.wd.sh * 0.7,
    });
  }
  componentDidUpdate(prevProps) {
    if (this.props.pagescrolled !== prevProps.pagescrolled) {
      var st = this.props.pagescrolled;
      this.setTranslate(st);
    }
  }
  setTranslate = (scroll) => {
    var ele = document.getElementById("section7");
    var TranslateLeft;
    var TranslateLeftPropertie;
    var LeftOpacity;
    var startAnimFactor = 0.3 * this.props.wd.wh;

    var ele_offsettop = ele.offsetTop;
    /*** set opacity Animation */
    var sectionScrollPercentageWithFactor =
      (100 * (scroll - (ele_offsettop - startAnimFactor))) / ele.clientHeight;
    if (scroll > ele_offsettop - startAnimFactor) {
      LeftOpacity =
        sectionScrollPercentageWithFactor * 0.05 < 1
          ? sectionScrollPercentageWithFactor * 0.05
          : 1;
    } else {
      LeftOpacity = 0;
    }
    this.setState({
      leftOpacity: LeftOpacity,
    });
    /*** set translate left Animation */
    TranslateLeft =
      this.state.translateLeft +
      (scroll - ele_offsettop + this.props.navheight);
    if (TranslateLeft > 0) {
      TranslateLeftPropertie = "translate3d(0px, 0px, 0px)";
    } else {
      TranslateLeftPropertie = "translate3d(0px," + TranslateLeft + "px, 0px)";
    }
    this.setState({
      translateLeftPropertie: TranslateLeftPropertie,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    var _form = $("form");
    var _submit = _form.find("button");
    var _inputs = _form.find("input, textarea");

    this.validateForm(_form, _inputs);

    _submit.attr("disabled", true);

    if (this.validateForm(_form, _inputs) > 0) {
      _form
        .removeClass("form-success form-error form-invalid")
        .addClass("form-error");

      _submit.attr("disabled", false);

      _inputs.off("focus.validation").on("focus.validation", function () {
        _form.removeClass("form-success form-error form-invalid");
      });

      return false;
    }

    _form.addClass("submit");

    let data = new FormData();

    data.append("name", this.state.name);
    data.append("email", this.state.email);
    data.append("msg", this.state.msg);

    axios.post(this.state.email_url, data).then((res) => {
      if (res.data == "success") {
        _submit.attr("disabled", false);

        _inputs.val("");
        _inputs.removeClass("error");

        _form
          .removeClass("submit")
          .removeClass("form-success form-error form-invalid")
          .addClass("form-success");

        $("#form-success").fadeIn();

        $(window).on("scroll.form click.form", function () {
          $("#form-success").fadeOut(600, function () {
            _form.removeClass("form-success form-error form-invalid");
            _inputs.removeClass("error");
          });
        });

        setTimeout(function () {
          $("#form-success").fadeOut(600, function () {
            _form.removeClass("form-success form-error form-invalid");
            _inputs.removeClass("error");
          });
        }, 10000);
      } else {
        _submit.attr("disabled", false);

        _form
          .removeClass("submit")
          .removeClass("form-success form-error")
          .addClass("form-invalid");

        return false;
      }
    });

    return false;
  };

  validateForm(_form, _inputs) {
    var input_name = $('form input[name="name"]'),
      input_email = $('form input[name="email"]'),
      input_msg = $('form textarea[name="msg"]'),
      errors = 0;

    // reset form to default state
    _form.removeClass("form-success form-error form-invalid");
    _inputs.removeClass("error");

    if (!input_name.val().length) {
      input_name.addClass("error");
      errors++;
    }
    if (!input_msg.val().length) {
      input_msg.addClass("error");
      errors++;
    }
    if (!input_email.val().length || !this.isValidEmail(input_email.val())) {
      input_email.addClass("error");
      errors++;
    }

    return errors;
  }

  isValidEmail(emailAddress) {
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    return pattern.test(emailAddress);
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render() {
    return (
      <div
        id="section7"
        className="section content_container"
        style={{
          width: this.props.wd.ww,
          height: this.props.wd.sh,
        }}
      >
        <div className="column left">
          <div className="content">
            <img
              alt="Imagem Título contactos"
              className="title opacity_animation transitionY_animation"
              style={{ opacity: this.state.leftOpacity }}
              src="images/section7_whitetitle.png"
            />
            <div
              className="text_content transitionY_animation"
              style={{ transform: this.state.translateLeftPropertie }}
            >
              <p>Para qualquer esclarecimento contacte-nos:</p>
              <div className="contacts_containers phone_content">
                <img alt="phone ícone" src="images/logo_phone.png" />
                <a href="tel:+351967139183">
                  <p>+351 967139183</p>
                </a>
              </div>
              <div className="contacts_containers email_content">
                <img alt="email ícone" src="images/logo_email.png" />
                <a href="mailto:info@nlbjjacademy.com">
                  <p>info@nlbjjacademy.com</p>
                </a>
              </div>
              <p>
                E se nos quiser conhecer melhor sem sair do sofá vá às nossas
                redes sociais. Se ainda não conseguimos, esperemos que o
                cativem.
              </p>
              <div className="social_containers">
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.facebook.com/poundforpoundcascais/"
                >
                  <div className="social_container facebook_container">
                    <img alt="facebook ícone" src="images/logo_facebook.png" />
                  </div>
                </a>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.instagram.com/p4p_alcabideche/"
                >
                  <div className="social_container instagram_container">
                    <img
                      alt="instagram ícone"
                      src="images/logo_instagram.png"
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className="column right"
          style={{
            backgroundImage: "url(images/section7_background1.jpg)",
          }}
        >
          <div
            className="backgroundImage opacity_animation"
            style={{
              backgroundImage: "url(images/section7_background2.jpg)",
              opacity: this.state.rightOpacity,
            }}
          ></div>
          <div className="content">
            <img
              alt="Imagem Título contacte-nos"
              className="title title_lg"
              style={{ transform: "translate3d(0px, 0px, 0px)" }}
              src="images/section7_blacktitle.png"
            />
            <div className="text_content">
              <p>
                Se preferir também pode contactar-nos directamente por aqui.
                Entraremos em contacto assim que possível.
              </p>
              <form onSubmit={this.onSubmit}>
                <input
                  type="text"
                  name="name"
                  placeholder="nome*"
                  onChange={this.onChange}
                  value={this.state.name}
                  required
                />
                <input
                  type="email"
                  name="email"
                  placeholder="email*"
                  onChange={this.onChange}
                  value={this.state.email}
                  required
                />
                <textarea
                  name="msg"
                  placeholder="mensagem*"
                  onChange={this.onChange}
                  value={this.state.msg}
                  required
                />
                <button type="submit">
                  <div className="btn from-bottom">
                    <p>ENVIAR</p>
                  </div>
                </button>
                <div className="form-result">
                  <div id="form-success" className="container-success">
                    <div>
                      <h2>O seu email foi enviado com sucesso.</h2>
                      <button className="bt bt-close">
                        <div className="div-bt-close">
                          <p className="ir">OSS</p>
                        </div>
                      </button>
                    </div>
                  </div>
                  <p className="txt-error">
                    Por favor, preencha os campos correctamente.
                  </p>
                  <p className="txt-invalid">
                    Ocorreu um erro no envio do email. <br /> Por favor tente
                    mais tarde.
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Section7;
